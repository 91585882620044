
import { defineComponent } from '@nuxtjs/composition-api'
import { mapGetters, mapMutations } from 'vuex'

export default defineComponent({
  computed: {
    ...mapGetters({
      hitsPerPage: 'overview/hitsPerPage',
      page: 'overview/page',
      totalHits: 'overview/totalHits',
    }),
  },
  methods: {
    ...mapMutations({
      setPage: 'overview/setPage',
    }),
  },
})
