
import { mapGetters, mapMutations } from 'vuex'
import { setupTranslationPrefixer } from '~/assets/translation'

export default {
  props: {
    title: { type: String, required: false, default: '' },
  },
  setup(props) {
    return {
      ...setupTranslationPrefixer('OverviewSearch'),
    }
  },
  data() {
    return { newKeyword: '' }
  },
  computed: {
    ...mapGetters({
      keyword: 'overview/keyword',
    }),
  },
  methods: {
    ...mapMutations({
      setKeyword: 'overview/setKeyword',
    }),
    search() {
      this.setKeyword(this.newKeyword)
    },
  },
}
