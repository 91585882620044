
import { mapGetters, mapMutations } from 'vuex'
import { setupTranslationPrefixer } from '~/assets/translation'
import filterLabel from '~/mixins/filterLabel'

export default {
  mixins: [filterLabel],
  props: {
    translationDomain: { type: String, required: true },
  },
  setup(props) {
    return {
      ...setupTranslationPrefixer('OverviewResultSummary', props.translationDomain),
    }
  },
  computed: {
    ...mapGetters({
      activeFacets: 'overview/activeFacets',
      totalHits: 'overview/totalHits',
      keyword: 'overview/keyword',
    }),
  },
  methods: {
    ...mapMutations({
      reset: 'overview/reset',
      setKeyword: 'overview/setKeyword',
      toggleFacet: 'overview/toggleFacet',
    }),
  },
}
