
import filterLabel from '@/mixins/filterLabel'
import { setupTranslationPrefixer } from '@/assets/translation'

export default {
  mixins: [filterLabel],
  props: {
    values: { type: Array, required: true },
    name: { type: String, required: true },
    toggleFacet: { type: Function, required: true },
  },
  setup() {
    return {
      ...setupTranslationPrefixer('OverviewFilterPanel'),
    }
  },
  data() {
    return {
      filterText: '',
    }
  },
  computed: {
    filteredFacets() {
      if (!this.filterText) {
        return this.values
      }
      const filterTextLower = this.filterText.toLowerCase()
      return this.values.filter((facet) => facet.label.toLowerCase().includes(filterTextLower))
    },
  },
}
