
import { mapGetters } from 'vuex'
import { setupTranslationPrefixer } from '~/assets/translation'

export default {
  setup() {
    return {
      ...setupTranslationPrefixer('card-related'),
    }
  },
  computed: {
    ...mapGetters({
      results: 'overview/results',
    }),
  },
  methods: {
    constructSubTitle(result) {
      switch (result.section) {
        case 'newsPage':
        case 'publicationPage':
        case 'storyPage':
          return result.postDate ? this.d_(result.postDate, 'long-date--2-digit') : null
        case 'eventPage':
          return [result.date ? this.d_(result.date, 'long-date--2-digit') : null, result.location].filter((x) => x).join(', ')
        case 'personPage':
          return result.profession
        default:
          return null
      }
    },
  },
}
