
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: { type: String, required: true, default: '' },
    titleLarge: { type: Boolean, required: false, default: false },
    subTitle: { type: String, required: false, default: '' },
    url: { type: String, required: false, default: '' },
    tags: { type: Array, required: false, default: undefined },
    phoneNumber: { type: String, required: false, default: undefined },
    phoneNumberDisplay: { type: String, required: false, default: undefined },
    emailAddress: { type: String, required: false, default: undefined },
    linkedin: { type: String, required: false, default: undefined },
    buttonText: { type: String, required: false, default: '' },
    image: { type: Object, required: false, default: undefined },
  },
})
