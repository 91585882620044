
import { computed, defineComponent, useStore } from '@nuxtjs/composition-api'
import { routerQueryIsEqual } from '~/assets/dynamic-url'
import { setupTranslationPrefixer } from '~/assets/translation'
import { STATUS } from '~/store/overview'

export default defineComponent({
  props: {
    page: { type: Object, required: true },
  },
  setup() {
    const store = useStore()

    return {
      activeFacets: computed(() => store.getters['overview/activeFacets']),
      facets: computed(() => store.getters['overview/facets']),
      urlParameters: computed(() => store.getters['overview/urlParameters']),
      overviewStatus: computed(() => store.getters['overview/status']),
      overviewIsReady: computed(() => store.getters['overview/status'] === STATUS.READY),
      ...setupTranslationPrefixer('Overview'),
    }
  },
  watch: {
    urlParameters(query) {
      if (this.overviewStatus !== STATUS.INITIALIZING && !routerQueryIsEqual(this.$route.query, query)) {
        this.$router.push({ query })
      }
    },
  },
})
