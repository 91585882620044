
import { defineComponent } from '@nuxtjs/composition-api'
import { truncate, stripTags } from '@zicht/nuxt-util'
import { setupTranslationPrefixer } from '~/assets/translation'

export default defineComponent({
  props: {
    title: { type: String, required: true, default: '' },
    text: { type: String, required: false, default: '' },
    subTitle: { type: String, required: false, default: '' },
    tags: { type: Array, required: false, default: () => [] },
    url: { type: String, required: true, default: '' },
  },
  setup() {
    return {
      truncate,
      stripTags,
      ...setupTranslationPrefixer('card-related'),
    }
  },
})
